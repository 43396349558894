import type { UserConnectionInfo } from '@amzn/safe-frame-client-mobilesfclient';

const CONNECTION_TYPE_UNKNOWN = 'unknown';
const MOBILE_CONNECTION_TYPE_UNKNOWN = 0;

const getConnectionInfo = () => {
    const navigatorObject: Navigator = window.navigator;
    const connection = navigatorObject?.connection;
    return connection?.type || connection?.effectiveType || CONNECTION_TYPE_UNKNOWN;
};

export const getUserNetworkConnectionInfo = (amznAppContextCookie?: string): UserConnectionInfo => {
    const mobileConnectionType = amznAppContextCookie
        ? getAmznAppContextFromCookie(amznAppContextCookie)?.di?.ct
        : MOBILE_CONNECTION_TYPE_UNKNOWN;
    console.error(mobileConnectionType);
    // User context is the data received from MASH application context
    return {
        connectionType: getConnectionInfo(),
        mobileConnectionType: mobileConnectionType || MOBILE_CONNECTION_TYPE_UNKNOWN,
    };
};

// https://w.amazon.com/bin/view/MASH/Application_Context_Protocol/1.5
type AmznAppContext = {
    di: {
        ct: number;
    };
};

/**
 * From https://w.amazon.com/bin/view/MASH/Application_Context_Protocol
 */
const getAmznAppContextFromCookie = (mashCookieValue: string): AmznAppContext => {
    const MASH_COOKIE_REGEX = /^([.\d]*\s*)({.*})$/;
    const cookieSplitArray = MASH_COOKIE_REGEX.exec(mashCookieValue) as RegExpExecArray;
    return JSON.parse(cookieSplitArray[2]);
};
