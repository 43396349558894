import type {
    LaunchIntentURLParameters,
    MashAPI,
    MashError,
    OpenInExternalBrowserParameters,
    SFOpenInExternalBrowser,
    ShowEmbeddedBrowserParameters,
    SFShowEmbeddedBrowser,
    OpenInApp3pBrowserParameters,
    SFOpenInApp3pBrowser,
} from '@amzn/safe-frame-client-mobilesfclient';
import { ToHostCommandType, ClientMessageSender } from './clientMessageSender.js';
import { CommonApiImplementation } from './CommonApiImplementation.js';
import { AdDetails } from '../../@types/adCommon.js';
import { InternalSFClientAPI } from './InternalSFClientAPI.js';

export interface MessageCallback<ExecuteType, CallbackType> {
    execute: (data: ExecuteType) => void;
    update: (data: CallbackType) => void;
}

interface MashCallbackFunction {
    successCallback?: () => void;
    failCallback?: (error: MashError) => void;
}
interface MashCallbackData {
    value?: object | string | number;
    errorMsg?: string;
}

class MessageCallbackImpl<ExecuteType extends MashCallbackFunction, CallbackType extends MashCallbackData>
    implements MessageCallback<ExecuteType, CallbackType>
{
    private _successCallback?: () => void;
    private _failCallback?: (error: MashError) => void;
    constructor(
        protected readonly messageName: ToHostCommandType,
        protected readonly c: InternalSFClientAPI,
        protected readonly cms: ClientMessageSender,
    ) {}

    execute = (data: ExecuteType) => {
        this.c.logAPIInvocation(this.messageName);
        this._successCallback = data.successCallback;
        this._failCallback = data.failCallback;
        this.cms.sendMessage(this.messageName, data);
    };

    update = (data: CallbackType) => {
        if (typeof data.value === 'undefined') {
            if (this._successCallback) {
                this._successCallback();
            }
        } else {
            if (this._failCallback) {
                // TODO Fix this type override
                this._failCallback(data.value || (data.errorMsg as any));
            }
        }
    };
}
export class OpenInExternalBrowser
    extends MessageCallbackImpl<OpenInExternalBrowserParameters, { value?: string }>
    implements SFOpenInExternalBrowser
{
    constructor(c: CommonApiImplementation, cms: ClientMessageSender) {
        super('openInExternalBrowser', c, cms);
    }
}

export class ShowEmbeddedBrowser
    extends MessageCallbackImpl<ShowEmbeddedBrowserParameters, { value?: string }>
    implements SFShowEmbeddedBrowser
{
    constructor(c: CommonApiImplementation, cms: ClientMessageSender) {
        super('showEmbeddedBrowser', c, cms);
    }
}

export class LaunchIntentURL
    extends MessageCallbackImpl<LaunchIntentURLParameters, { value?: string }>
    implements LaunchIntentURL
{
    constructor(c: CommonApiImplementation, cms: ClientMessageSender) {
        super('launchIntentURL', c, cms);
    }
}

export class OpenInApp3pBrowser
    extends MessageCallbackImpl<OpenInApp3pBrowserParameters, { value?: string }>
    implements SFOpenInApp3pBrowser
{
    constructor(c: CommonApiImplementation, cms: ClientMessageSender) {
        super('openInApp3pBrowser', c, cms);
    }
}

export class MashAPIImpl implements MashAPI {
    readonly openInExternalBrowser: OpenInExternalBrowser;
    readonly showEmbeddedBrowser: ShowEmbeddedBrowser;
    readonly launchIntentURL: LaunchIntentURL;
    readonly openInApp3pBrowser: OpenInApp3pBrowser;
    constructor(
        c: CommonApiImplementation,
        cms: ClientMessageSender,
        private o: AdDetails,
    ) {
        this.openInExternalBrowser = new OpenInExternalBrowser(c, cms);
        this.showEmbeddedBrowser = new ShowEmbeddedBrowser(c, cms);
        this.launchIntentURL = new LaunchIntentURL(c, cms);
        this.openInApp3pBrowser = new OpenInApp3pBrowser(c, cms);
    }

    /**
     * TODO Remove this
     * @deprecated This is always true and also irrelevant since the client has no direct access to the AUI anyways
     */
    isAUIAvailable = (): boolean => {
        return true;
    };
}
